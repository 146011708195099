/* Use this file to add custom css to the theme. */
.dynamic-variant-button.product__chip.disabled {display:none;}

/* footer payment icons */

ul.inline-list.payment-icons.footer__section {
  justify-content: center;
    width: 100% !important;
    margin: 10px 0px
}
li.icon--payment {
    list-style-type: none;
    margin: 0px 5px;
}

/* pop-up */

.popup {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.popup-content {
  width: 30%;
  position: relative;
  background-color: #fff;
  padding: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
}

.popup-close {
       top: -1px;
    right: 7px;
    position: absolute;
    cursor: pointer;
    text-align: center;
    font-size: 25px;
}

.custom-1 {
  margin: auto;
    text-align: center;
    width: 85%;
}
.custom-1 h3 {
    margin-top: 15px;
    font-weight: 600;
    text-transform: none;
    font-family: sans-serif;
    font-size: 20px;
}
.custom-2 {
  margin: 30px 0px;
}
.custom-2 p {
  font-size: 12px;
    font-family: sans-serif;
}
.popup-cta {
    margin-bottom: 15px;
}
.input-custom {
  background: white;
  border: 1px solid #0000006b;
}
@media only screen and (min-width: 768px) and (max-width: 1300px) {
  .popup-content {
    width: 40%;
  }
}
@media only screen and (min-width: 501px) and (max-width: 767px) {
  .popup-content {
    width: 60%;
  }
  .custom-1 h3 {
    font-size: 19px;
  }
}
@media only screen and (min-width: 1301px) {
  .popup-content {
    width: 30%;
  }
}
@media only screen and (max-width: 500px) {
  .popup-content {
    width: 80%;
  }
  .custom-1 h3 {
    font-size: 16px;
  }
}
.button-wrapper-text {
  margin-top: 13px;
  display: block;
    width: 100%;
    text-align: center !important;
}
.button-link-text {
 color: white;
    border: 1px solid white;
    padding: 10px 15px;
    background: #db9b29;
}



.image-hero-split-item__text-container-inner.section-blocks.animation--section-blocks a {
    border-radius: 5px;
    background: black;
    opacity: 0.7;
}

/* new label */

span.new-label {
    text-transform: uppercase;
    font-family: var(--font-body) !important;
    line-height: 1rem;
    padding: 0.3em;
    font-size: 17px;
    top:var(--spacing-2);
    left:var(--spacing-2);
    position: absolute;
    color: #000;
    z-index: 10;
    background: white;
    height: 25px;
}

.product-item .product-badges {
  left: unset !important;
  right:var(--spacing-2) !important;
}
